import { createI18n, LocaleMessageDictionary, VueMessageType } from 'vue-i18n'

export function setupI18n() {
  return createI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {},
  })
}

export const i18n = setupI18n()

export function setI18nLanguage(locale: string) {
  i18n.global.locale = locale
}

type LocaleMessage = LocaleMessageDictionary<VueMessageType>

export function setLocaleTranslations(locale: string, translations: LocaleMessage) {
  i18n.global.setLocaleMessage(locale, translations)
  setI18nLanguage(locale)
}
