import {
  IResponseCreative,
  IResponseFormat,
  IResponseStudies,
  IResponseStudiesFormat,
} from '@/api/types'

export interface IBaseCreativeModel {
  id: number
  name: string
  url: string
  category: string
  description: string
  advantages: string | null
  slug: string
  isMobile: boolean
  showScrollButton: boolean
}

export interface ICreativeModel extends IBaseCreativeModel {
  image: string
}

export type IStudiesCreativesModel = IBaseCreativeModel

export interface IBaseFormatModel {
  id: number
  name: string
}

export interface IFormatModel extends IBaseFormatModel {
  creatives: ICreativeModel[]
}

export interface IStudiesFormatModel extends IBaseFormatModel {
  creatives: IStudiesCreativesModel[]
}

export type IUnionFormatModel = IFormatModel | IStudiesFormatModel

export function newCreative(data: IResponseCreative): ICreativeModel {
  return {
    id: data.id,
    name: data.name,
    url: data.url,
    category: data.category,
    description: data.description,
    advantages: data.advantages,
    slug: data.slug,
    image: data.image,
    isMobile: data.is_mobile,
    showScrollButton: data.show_scroll_button,
  }
}

export function newStudies(data: IResponseStudies): IStudiesCreativesModel {
  return {
    id: data.id,
    name: data.name,
    url: data.url,
    category: data.category,
    description: data.description,
    advantages: data.advantages,
    slug: data.slug,
    isMobile: data.is_mobile,
    showScrollButton: data.show_scroll_button,
  }
}

export function newFormatCreatives(data: IResponseFormat): IFormatModel {
  return {
    id: data.id,
    name: data.name,
    creatives: data.creatives.map((creative) => newCreative(creative)),
  }
}

export function newFormatStudiesCreatives(data: IResponseStudiesFormat): IStudiesFormatModel {
  return {
    id: data.id,
    name: data.name,
    creatives: data.creatives.map((creative) => newStudies(creative)),
  }
}
