import { defineStore } from 'pinia'
import { setLocaleTranslations } from '@/plugins/i18n'
import { loadTranslations } from '@/api/languages'

export const useTranslationsStore = defineStore('translations', {
  actions: {
    async loadTranslations(language: string): Promise<void> {
      const translation = await loadTranslations(language)
      setLocaleTranslations(
        language,
        translation,
      )
    },
  },
})
