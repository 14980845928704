<template>
  <svg :class="className" xmlns="http://www.w3.org/2000/svg">
    <use :href="iconPath"/>
  </svg>
</template>

<script>
export default {
  name: 'svg-icon',
  props: {
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    iconPath() {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require,import/no-dynamic-require
      let icon = require(`@/assets/icons/${this.name}.svg`)
      if (Object.prototype.hasOwnProperty.call(icon, 'default')) {
        icon = icon.default
      }

      return icon.url
    },

    className() {
      return `svg-icon svg-icon--${this.name}`
    },
  },
}
</script>

<style>
  .svg-icon {
    fill: currentColor;
    height: 24px;
    width: 24px;
  }
</style>
