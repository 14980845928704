import { defineStore } from 'pinia'
import { IDomain } from '@/models/domain'
import { loadSupportedDomains } from '@/api/domains'

interface IDomainState {
  domains?: IDomain[],
  defaultDomain: IDomain,
  currentDomain?: string,
  loaded: boolean,
}

export const useDomainsStore = defineStore('domains', {
  state: (): IDomainState => ({
    loaded: false,
    domains: undefined,
    defaultDomain: {
      id: 1,
      name: 'localhost',
      urlLanding: 'localhost',
      urlGallery: 'localhost',
      placeType: 'AFP',
      description: '',
      title: '',
      favicon: '',
      supportedLanguage: [
        {
          id: 1,
          name: 'en',
        },
      ],
      defaultLanguage: {
        id: 1,
        name: '',
      },
      logo: '',
      mobileLogo: '',
      theme: {},
      showRecommendationButton: false,
    },
    currentDomain: undefined,
  }),
  getters: {
    currentDomainSettings(): IDomain {
      return this.domains?.find(
        (domain) => domain.name === this.currentDomain,
      ) || this.defaultDomain
    },
  },
  actions: {
    async loadDomains(): Promise<void> {
      this.domains = await loadSupportedDomains()
      this.loaded = true
    },
    setCurrentDomain(domain: string): void {
      if (domain.includes('localhost')) {
        domain = process.env.VUE_APP_DEFAULT_DOMAIN ?? 'cases.afp.ai'
      }
      this.currentDomain = domain
    },
  },
})
