import { createApp } from 'vue'
import { createPinia } from 'pinia'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/styles/index.scss'
import { i18n } from '@/plugins/i18n'
import App from './App.vue'
import router from './router'

createApp(App)
  .use(createPinia())
  .use(i18n)
  .use(ElementPlus)
  .use(router)
  .mount('#app')
