import { IResponseDomain, IResponseSupportedLanguage } from '@/api/types'

export interface ISupportedLanguage {
  id: number
  name: string
}

export function newSupportedLanguage(data: IResponseSupportedLanguage): ISupportedLanguage {
  return {
    id: data.id,
    name: data.name,
  }
}

export interface IDomain {
  id: number
  name: string
  urlGallery: string
  urlLanding: string
  supportedLanguage: ISupportedLanguage[]
  defaultLanguage: ISupportedLanguage
  logo: string
  mobileLogo: string
  placeType: string
  // eslint-disable-next-line
  theme: any,
  description: string
  favicon: string
  title: string
  showRecommendationButton: boolean
}

export function newDomain(data: IResponseDomain): IDomain {
  return {
    id: data.id,
    name: data.name,
    urlLanding: data.url_landing,
    urlGallery: data.url_gallery,
    supportedLanguage: data.supported_language.map((lang) => newSupportedLanguage(lang)),
    defaultLanguage: data.default_language,
    logo: data.logo,
    theme: data.theme,
    mobileLogo: data.mobile_logo,
    placeType: data.place_type,
    description: data.description,
    favicon: data.favicon,
    title: data.title,
    showRecommendationButton: data.show_recommendation_button,
  }
}
