import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { useDomainsStore } from '@/store/domains'
import { useFavicon, useTitle } from '@vueuse/core'
import MainView from '../views/MainView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:language',
    name: 'creative menu',
    component: MainView,
  },
  {
    path: '/:language/:slug/',
    name: 'creative',
    component: MainView,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

function customiseHead(
  {
    title, description, favicon,
  }: {title: string, description: string, favicon: string},
): void {
  const faviconHook = useFavicon()
  const titleHook = useTitle()
  faviconHook.value = favicon
  titleHook.value = title
  const descEl = document.querySelector('head meta[name="description"]')
  descEl?.setAttribute('content', description)
}

router.beforeEach(async (to, from, next) => {
  const domainsStore = useDomainsStore()
  if (!domainsStore.loaded) {
    domainsStore.setCurrentDomain(window.location.host)
    await domainsStore.loadDomains()
    customiseHead({
      title: domainsStore.currentDomainSettings.title,
      description: domainsStore.currentDomainSettings.description,
      favicon: domainsStore.currentDomainSettings.favicon,
    })
  }
  const supportedLanguage = domainsStore.currentDomainSettings.supportedLanguage.map(
    (lang) => lang.name,
  )
  if (!supportedLanguage.includes(to.params.language as string)) {
    next(`/${domainsStore.currentDomainSettings.defaultLanguage.name || 'en'}/`)
  }
  if (to.path === '/') {
    next(`/${domainsStore.currentDomainSettings.defaultLanguage.name}/`)
  }
  return next()
})

export default router
