import { ref } from 'vue'
import { useEventListener } from '@/composable/eventListener'

export function useMedia(query: string) {
  const mediaQuery = window.matchMedia(query)
  const matches = ref(mediaQuery.matches)

  useEventListener(mediaQuery, 'change', (e: Event) => {
    matches.value = (e as MediaQueryListEvent).matches
  })

  return matches
}
