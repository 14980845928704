import { defineStore } from 'pinia'
import { IBaseFormatModel, IFormatModel, IStudiesFormatModel } from '@/models/creative'
import { loadCreatives, loadStudiesCreatives } from '@/api/creatives'

interface ICreativesState {
  loaded: boolean,
  currentIsStudies: boolean,
  studiesFormats?: IStudiesFormatModel,
  formats: IFormatModel[],
}

export const useCreativeStore = defineStore('creatives', {
  state: (): ICreativesState => ({
    loaded: false,
    studiesFormats: undefined,
    formats: [],
    currentIsStudies: false,
  }),
  actions: {
    async loadCreatives(domain: string, language: string): Promise<void> {
      [this.formats, this.studiesFormats] = await Promise.all([
        loadCreatives({ language, domain }),
        loadStudiesCreatives({ language, domain }),
      ])
      this.loaded = true
    },
    setCurrentIsStudiesCreative() {
      this.currentIsStudies = true
    },
    setCurrentIsCreative() {
      this.currentIsStudies = false
    },
  },
  getters: {
    allFormats(): IBaseFormatModel[] {
      const allFormats: IBaseFormatModel[] = this.formats ? [...this.formats] : []
      if (this.studiesFormats) {
        allFormats.push(this.studiesFormats)
      }
      return allFormats
    },
  },
})
