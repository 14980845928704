import { axios } from '@/plugins/axios'
import {
  newFormatCreatives,
  IFormatModel,
  newFormatStudiesCreatives,
  IStudiesFormatModel,
} from '@/models/creative'
import { IResponseFormat, IResponseStudiesFormat } from '@/api/types'

interface LoadCreativesParams {
  language: string
  domain: string
}

export const loadCreatives = async (
  {
    language, domain,
  } : LoadCreativesParams,
): Promise<IFormatModel[]> => {
  const response = await axios.get<IResponseFormat[]>(
    `/creatives/?domain=${domain}&language=${language}`,
  )
  return response.data.map((format) => newFormatCreatives(format))
}

export const loadStudiesCreatives = async (
  {
    language, domain,
  } : LoadCreativesParams,
): Promise<IStudiesFormatModel | undefined> => {
  const response = await axios.get<IResponseStudiesFormat[]>(
    `/studies/?domain=${domain}&language=${language}`,
  )
  return response.data.map((format) => newFormatStudiesCreatives(format))[0]
}
